import { createGlobalStyle } from 'styled-components';
import { main, white, dark } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import '@fontsource/lato';
import '@fontsource/lato/400-italic.css';
import '@fontsource/playfair-display/400-italic.css';
import '@fontsource/playfair-display/900.css';
import '@fontsource/playfair-display/900-italic.css';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  a {
    color: ${main};
  	font-size: inherit;
  	text-decoration: none;
  	font-family: "Avenir Next Heavy", -apple-system, "Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Lato", -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1.6rem;
    color: ${dark};
    background-color: ${white};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  .container {
  position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 350px;
	line-height: 1.2;
	${MEDIA.MIN_PHONE`
			max-width: 703px;
  `};
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Avenir Next Heavy", -apple-system, "Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

h1 {
	font-size: 44px;
	font-weight: bold;
	font-style: italic;
 	color: ${dark};
}

h2 {
	font-size: 30px;
	font-weight: bold;
	font-style: italic;
 	color: ${dark};
}

h3 {
	font-size: 24px;
	font-weight: bold;
	font-style: italic;
 	color: ${dark};
}

h5 {
		font-size: 44px;
	font-weight: bold;
 	color: ${dark};
 	margin-bottom: 20px;
}



h6 {
	font-size: 17px;
	font-style: italic;
	color: ${dark};

}

hr {
			margin-top: 60px;
			margin-bottom: 25px;
			width: 303px;
 
}

.hide-mobile {
	display: none;

	${MEDIA.MIN_PHONE`
		display: block;
	`};
}

i { 
	font-family: "Lato";
	font-weight: normal;
	font-style: italic;
}

.lesson-table {
		font-style: italic;
	margin-top: 60px;
	margin-bottom: 100px;
	font-family: "Avenir Next Heavy", -apple-system, "Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}


  ol, ul {
    list-style: none;
  }

  p {
  	font-size: 20px;
  	line-height: 1.5;
  }

    .video-container {
  position: relative;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

`;
